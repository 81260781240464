var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-context-toggle" },
    [
      _vm.groupedChatContext.length > 1
        ? _c("RtbToggleButton", {
            staticClass: "chat-context-toggle__contxt-toggle-group",
            attrs: {
              value: _vm.selectedContext,
              items: _vm.groupedChatContext,
              size: "sm",
            },
            on: {
              change: function (val) {
                return _vm.$emit("localContextChange", val)
              },
            },
          })
        : _vm._e(),
      _vm.isHost && !_vm.isModerator
        ? _c(
            "div",
            {
              staticClass: "chat-context-toggle__staff",
              class: _vm.isStaffChat
                ? "chat-context-toggle__staff--active"
                : "",
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { flat: "", small: "" },
                  on: { click: _vm.toggleStaffChat },
                },
                [
                  _vm._v(" STAFF "),
                  _vm.staffUnreadMessage
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "chat-context-toggle__staff__unread-message",
                        },
                        [_vm._v(_vm._s(_vm.staffUnreadMessage))]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }