var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "chat-message-body", on: { scroll: _vm.scroll } },
    [
      _vm._t("messages", function () {
        return _vm._l(_vm.messages, function (ref, index) {
          var messagePayload = ref.messagePayload
          var id = ref.id
          var userDetail = ref.userDetail
          return _c(
            "div",
            {
              key: index + "-" + id,
              staticClass: "message-wrap",
              class: _vm.user.id === messagePayload.fromID ? "right" : "left",
            },
            [
              _c("div", { staticClass: "name-date" }, [
                _c(
                  "span",
                  {
                    class: { "name-clickable": _vm.isLobbyChatContext },
                    on: {
                      click: function ($event) {
                        return _vm.navigateToGame(messagePayload)
                      },
                    },
                  },
                  [
                    _c("UserName", {
                      attrs: {
                        firstname:
                          (userDetail && userDetail.firstname) || "Unknown",
                        lastname:
                          (userDetail && userDetail.lastname) || "Unknown",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" - "),
                _c("span", { staticClass: "message-time" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.getChatTime(messagePayload.timestamp)) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "message" }, [
                _vm._v(_vm._s(messagePayload.message)),
              ]),
            ]
          )
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }