<template>
  <v-flex class="chat-message-body" @scroll="scroll">
    <slot name="messages">
      <div
        class="message-wrap"
        v-for="({ messagePayload, id, userDetail }, index) in messages"
        :class="user.id === messagePayload.fromID ? 'right' : 'left'"
        :key="`${index}-${id}`"
      >
        <div class="name-date">
          <span
            :class="{ 'name-clickable': isLobbyChatContext }"
            @click="navigateToGame(messagePayload)"
          >
            <UserName
              :firstname="(userDetail && userDetail.firstname) || 'Unknown'"
              :lastname="(userDetail && userDetail.lastname) || 'Unknown'"
            />
          </span>
          -
          <span class="message-time">
            {{ getChatTime(messagePayload.timestamp) }}
          </span>
        </div>
        <div class="message">{{ messagePayload.message }}</div>
      </div>
    </slot>
  </v-flex>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import moment from "moment"

import UserName from "@/components/GroupTeams/Common/User/UserName.vue"

import Chat from "./"
import { LAZYLOAD_MESSAGE_LIMIT, CHAT_CONTEXTS } from "./constants"
import { isColorDark } from "@/modules/theme/utils"

import { fetchUser } from "@/services/user.service"

export default Vue.extend({
  name: "Messages",
  components: {
    UserName
  },
  data() {
    return {
      lazyLoad: true,
      isScrolling: false,
      lazyLoadedMessages: [],
      isScrollingTimerID: null
    }
  },
  updated() {
    this.initialUpdate = true
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("auth", ["isHost"]),
    isLobbyPage() {
      return this.$route.name === "pregame"
    },
    isLobbyChatContext() {
      return CHAT_CONTEXTS.LOBBY === this.context && this.isLobbyPage
    },
    slotData() {
      return {
        messages: this.messagePayload,
        navigateToGame
      }
    }
  },
  watch: {
    messages: {
      handler() {
        this.$nextTick(async () => {
          if (!this.isScrolling) this.scrollToEnd()
          Chat.updateActiveReadTimestamp({
            contextRef: this.contextRef,
            userID: this.user.id
          })
        })
      },
      immediate: true
    }
  },
  mounted() {
    const primaryAccentColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--primary-accent-color")

    const color = isColorDark(primaryAccentColor) ? `#fff` : `#000`
    document.documentElement.style.setProperty("--right-message-color", color)
  },
  methods: {
    scrollToEnd() {
      setTimeout(() => {
        try {
          this.$el.scrollTop = this.$el.lastElementChild.offsetTop
        } catch (e) {}
      }, 0)
    },
    getChatTime(timestamp) {
      return moment(timestamp)
        .fromNow()
        .replace(/^0(?:0:0?)?/, "")
    },
    async scroll($event) {
      const fetchLimit = LAZYLOAD_MESSAGE_LIMIT
      if ($event.target.scrollTop === 0 && this.canLazyLoadMessage) {
        this.isScrolling = true
        this.$emit("LAZYLOAD_MESSAGE", {
          contextRef: this.contextRef,
          limit: fetchLimit
        })
        clearTimeout(this.isScrollingTimerID)
        this.isScrollingTimerID = setTimeout(() => {
          this.isScrolling = false
        }, 1000)
      }
    },
    async navigateToGame({ fromID }) {
      if (!this.isLobbyChatContext || !fromID) return
      const { gameID } = await fetchUser({ userID: fromID })
      if (!gameID) return
      this.$emit("onSelectedGameIDUpdate", { gameID })
    }
  },
  props: {
    messages: {
      required: true,
      type: Array
    },
    teamID: {
      required: true
    },
    contextRef: {
      required: true
    },
    canLazyLoadMessage: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      required: true
    }
  }
})
</script>

<style lang="scss">
.chat-message-body {
  background: #fcfbfb;
  position: relative;
  overflow: auto;
  clear: both;
  min-height: 100px;

  .message-wrap {
    border-radius: 5px;
    max-width: 80%;
    padding: 5px;
    margin: 3px 8px;
    overflow: hidden;
    overflow-wrap: break-word;
    text-align: left;
    clear: both;
    .name-date {
      font-weight: bolder;
      margin-bottom: 2px;
      .message-time {
        font-weight: lighter;
        font-size: 12px;
        margin-left: 3px;
      }
      .name-clickable {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .message {
      font-size: 16px;
      color: currentColor;
    }
    &.right {
      background: $primary_accent_color;
      color: var(--right-message-color);
    }
    &.left {
      background: #d1cecd;
      color: #000;
    }
  }

  &::-webkit-scrollbar {
    width: 5px !important;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: inherit !important;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary_accent_color !important;
    // border: 1px solid $color-primary-dark !important;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary_accent_color;
    filter: brightness(1.2);
  }
}
</style>
