<template>
  <div class="chat-context-toggle">
    <RtbToggleButton
      v-if="groupedChatContext.length > 1"
      :value="selectedContext"
      :items="groupedChatContext"
      :size="'sm'"
      class="chat-context-toggle__contxt-toggle-group"
      @change="val => $emit('localContextChange', val)"
    />
    <div
      class="chat-context-toggle__staff"
      :class="isStaffChat ? 'chat-context-toggle__staff--active' : ''"
      v-if="isHost && !isModerator"
    >
      <v-btn flat small @click="toggleStaffChat">
        STAFF
        <span
          class="chat-context-toggle__staff__unread-message"
          v-if="staffUnreadMessage"
          >{{ staffUnreadMessage }}</span
        >
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import { RtbToggleButton } from "@/components/ui"
import { CHAT_CONTEXTS } from "./constants.js"

export default {
  name: "ChatContextToggle",
  components: {
    RtbToggleButton
  },
  props: {
    contexts: {
      type: Array,
      default: () => []
    },
    selectedContext: {
      default: "",
      type: String
    }
  },
  watch: {
    groupedChatContext: {
      handler(val) {
        if (val.length === 1) {
          const [context] = val

          this.$emit("localContextChange", context.value)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "isModerator"]),
    groupedChatContext() {
      return this.contexts.filter(ctx => ctx.value !== CHAT_CONTEXTS.STAFF)
    },
    isStaffChat() {
      return CHAT_CONTEXTS.STAFF === this.value
    },
    staffUnreadMessage() {
      return (
        this.contexts.filter(ctx => ctx.value === CHAT_CONTEXTS.STAFF)?.[0]
          ?.pill || ""
      )
    }
  },
  methods: {
    toggleStaffChat() {
      if (
        this.value === CHAT_CONTEXTS.STAFF &&
        this.groupedChatContext.length <= 1
      ) {
        this.value = null
        this.$emit("localContextChange", null)
      } else {
        this.value = CHAT_CONTEXTS.STAFF
        this.$emit("localContextChange", this.value)
      }
    }
  }
}
</script>

<style lang="scss">
.chat-context-toggle {
  display: flex;
  width: 100%;
  justify-content: center;

  &__contxt-toggle-group {
    width: 70%;
    z-index: 1;
    border: 2px solid #787a7c;

    .v-btn--active:before,
    .v-btn:hover:before,
    .v-btn:focus:before {
      background-color: unset;
    }
  }

  &__staff {
    margin-left: 4px;

    &--active {
      .v-btn__content {
        background-color: #8680ff;
        padding: 5px;
      }
    }

    &__unread-message {
      display: flex;
      width: 20px;
      height: 20px;
      background: #8680ff;
      font-size: 11px;
      align-items: center;
      border-radius: 50%;
      position: absolute;
      justify-content: center;
      top: -14px;
      right: -4px;
    }

    .v-btn--small {
      font-size: 13px;
      height: unset;
      padding: 1px 4px;
    }
    .v-btn {
      margin: 0;
      min-width: unset;
      border-radius: 25px !important;
      opacity: 1;
      padding-top: 5px;
      padding-bottom: 5px;
      height: 30px;
      width: 60px;
    }

    .v-btn__content {
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
</style>
