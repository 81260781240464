

import Vue from "vue"

import Collection from "@shared/Collection"

import Chat from "@/components/Chat/Chat.vue"
import ChatHead from "@/components/Chat/ChatHead.vue"

import { mapGetters } from "vuex"
import Mode from "@shared/enums/Mode"

import { CHAT_CONTEXTS, ALL_CHAT_CONTEXT } from "./constants"
import ChatService from "./index.js"

import { db } from "@/firebase"

const database: any = db

import linkifyStr from "linkifyjs/string"
import { debounce, isEqual } from "lodash"

interface Message {
  firstname: string
  fromID: string
  lastname: string
  message: string
  role: string
  timestamp: number
}

export default Vue.extend({
  name: "ChatWrap",
  components: {
    Chat,
    ChatHead
  },
  filters: {
    normalizeMessage: function (value) {
      if (!value) return ""
      value = value.toString()
      return linkifyStr(value.replace(/\n\r?/g, "<br />"), {
        target: {
          url: "_blank"
        }
      })
    },
    messageTime(value) {
      value = parseInt(value)
      value = value < 10000000000 ? value * 1000 : value
      const date = new Date(value)
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const amPm = hours >= 12 ? "pm" : "am"
      const hoursFormat = hours % 12 || 12
      const minutesFormat = minutes >= 10 ? minutes : "0" + minutes
      return `${hoursFormat}:${minutesFormat}${amPm}`
    },
    unreadMessageFormat(value) {
      value = parseInt(value) || 0
      return value > 9 ? "9+" : value
    }
  },
  data() {
    return {
      isChatVisible: false,
      localContext: null,
      chatContexts: ALL_CHAT_CONTEXT.map(({ name, value, pill }) => ({
        name,
        value,
        pill
      })),
      checkedMessages: {},
      isOldLobbyChatWrap: false
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "isModerator", "clientID", "client"]),
    ...mapGetters([
      "teamID",
      "game",
      "gameID",
      "isLobbyHostChat",
      "hasPreGame"
    ]),
    ...mapGetters({ mode: "getCurrentMode", teams: "chats" }),
    getTeamID() {
      if (this.isHost) {
        const teams = Collection.normalize(this.teams || {}).filter(
          team => team.show !== false
        )
        const globalTeamID = this.$store.getters["group/globalTeamID"]
        return teams.some(team => team?.id === globalTeamID)
          ? globalTeamID
          : teams?.[0]?.id ?? ""
      } else {
        return this.teamID
      }
    },
    isGamePage() {
      return this.$route.name === "game"
    },
    isLobbyPage() {
      return this.$route.name === "pregame" || this.$route.name === "lobby"
    },
    isGameChat() {
      return this.mode === Mode.Meeting
    },
    titleDisplayName() {
      const titleDisplayName = { primary: "", secondary: "", reduced: false }
      if (
        [CHAT_CONTEXTS.LOBBY, CHAT_CONTEXTS.LOBBY_HOST].includes(
          this.getChatContext
        )
      ) {
        titleDisplayName.primary = "Chatting with the lobby"
        titleDisplayName.secondary =
          "Everyone in the lobby can read your messages"
      } else if (CHAT_CONTEXTS.GAME === this.getChatContext) {
        titleDisplayName.primary = "You're Chatting with the room"
        titleDisplayName.secondary =
          "All room participants can read your messages"
      } else if (CHAT_CONTEXTS.TEAM === this.getChatContext) {
        titleDisplayName.primary = "Chatting with your team"
        titleDisplayName.secondary = "Only your team can see your messages"
      } else if (CHAT_CONTEXTS.STAFF === this.getChatContext) {
        titleDisplayName.primary = "You are chatting with super hosts"
        titleDisplayName.reduced = true
        titleDisplayName.secondary = "Only Super Hosts can send Messages"
      }
      return titleDisplayName
    },
    messagePlaceholder() {
      return CHAT_CONTEXTS.STAFF === this.getChatContext
        ? "Message sent will notify all staff..."
        : "Enter your message..."
    },
    getChatContext() {
      if (this.isLobbyPage) {
        if (this.localContext === CHAT_CONTEXTS.STAFF) return this.localContext
        if (this.isLobbyHostChat) return CHAT_CONTEXTS.LOBBY_HOST
        if (
          this.localContext &&
          [
            ...this.getAvailableGameChatContext,
            CHAT_CONTEXTS.LOBBY_HOST
          ].includes(this.localContext)
        ) {
          return this.localContext
        }
        return CHAT_CONTEXTS.LOBBY
      } else if (this.isGamePage) {
        if (this.isLobbyHostChat && this.localContext === CHAT_CONTEXTS.LOBBY) {
          return CHAT_CONTEXTS.LOBBY_HOST
        }

        if (
          this.localContext &&
          [
            ...this.getAvailableGameChatContext,
            CHAT_CONTEXTS.STAFF,
            CHAT_CONTEXTS.LOBBY_HOST
          ].includes(this.localContext)
        ) {
          return this.localContext
        }
        return this.isGameChat
          ? CHAT_CONTEXTS.GAME
          : this.getAvailableGameChatContext[0] || CHAT_CONTEXTS.TEAM
      }
      return null
    },
    contextLabel() {
      return ChatService.getContextLabel(this.getChatContext)
    },
    canSendMessage() {
      if (CHAT_CONTEXTS.STAFF !== this.getChatContext) {
        return true
      } else {
        return !!this.isSuper
      }
    },
    availableChatOptions() {
      let options = [...(this.getAvailableGameChatContext || [])]

      if (!options.length) {
        options.push(CHAT_CONTEXTS.TEAM)
      }

      if (this.isHost && !this.isModerator) {
        options.push(CHAT_CONTEXTS.STAFF)
      }

      return options.map(value => ({
        value,
        ref: this.getContextRefByValue(value)
      }))
    },
    availableChatOptionsMap() {
      return this.availableChatOptions.reduce((acc, cur) => {
        acc[cur.value] = true
        return acc
      }, {})
    },
    availableChatOptionsWatchValue() {
      return this.availableChatOptions.reduce(
        (acc, cur) => acc + "*" + cur.ref,
        this.isChatVisible
      )
    },
    availableChatContext() {
      const availableChatOptionsMap = this.availableChatOptionsMap || {}
      return this.chatContexts.filter(ctx => availableChatOptionsMap[ctx.value])
    },
    allUnreadMessage() {
      return this.availableChatContext.reduce((acc, cur) => {
        if (cur.pill === "9+") {
          return 10 + acc
        }
        return (parseInt(cur.pill) || 0) + acc
      }, 0)
    },
    getAvailableGameChatContext() {
      let options = []
      if (this.isGamePage) {
        if (this.$store.getters.isTeamChatOn()) options.push(CHAT_CONTEXTS.TEAM)
        if (this.$store.getters.isRoomChatOn()) options.push(CHAT_CONTEXTS.GAME)
        if (this.$store.getters.isLobbyChatOn())
          options.push(CHAT_CONTEXTS.LOBBY)
      } else if (this.isLobbyPage) {
        options = [CHAT_CONTEXTS.LOBBY]
        if (this.isHost) options.push(CHAT_CONTEXTS.STAFF)
      }
      return options
    },
    getChatRoomID() {
      const context = this.getChatContext
      if ([CHAT_CONTEXTS.LOBBY, CHAT_CONTEXTS.LOBBY_HOST].includes(context))
        return this.clientID
      else if (context === CHAT_CONTEXTS.GAME) return this.gameID
      else if (context === CHAT_CONTEXTS.TEAM) return this.getTeamID
      return null
    },
    isAnonymousChat() {
      if (
        [CHAT_CONTEXTS.GAME, CHAT_CONTEXTS.TEAM].includes(this.getChatContext)
      ) {
        return Boolean(this.game?.anonymousChat)
      } else if ([CHAT_CONTEXTS.LOBBY].includes(this.getChatContext)) {
        return Boolean(this.client?.anonymousChat)
      }
    },
    unreadAtHostMessages() {
      return Object.values(this.checkedMessages).filter(
        item => item === "@host"
      )
    },
    numberUnreadAtHost() {
      return this.unreadAtHostMessages?.length
    }
  },
  created() {
    ALL_CHAT_CONTEXT.forEach(context => {
      context.ref = this.getContextRefByValue(context.value)
    })
  },
  beforeDestroy() {
    for (const context of ALL_CHAT_CONTEXT) {
      context.unsubscribe && context.unsubscribe()
    }
  },
  methods: {
    showChat() {
      this.isChatVisible = true
    },
    hideChat() {
      this.isChatVisible = false
    },
    onUpdateLocalContext(context) {
      this.localContext = context
    },
    onLocalContextChange(val) {
      this.onUpdateLocalContext(val)
    },
    getContextRefByValue(context) {
      return ChatService.getRefByContext({
        context,
        orgID: this.orgID,
        gameID: this.gameID,
        teamID: this.getTeamID,
        clientID: this.clientID
      })
    },
    async subscribe(context) {
      if (!context.ref) return

      if (context.unsubscribe) context.unsubscribe()

      const lastActiveChatTimestamp =
        await ChatService.getLastActiveChatTimestamp({
          contextRef: context.ref,
          userID: this.user.id
        })

      const reactiveContext = this.chatContexts.find(
        ctx => ctx.value === context.value
      )

      if (reactiveContext) {
        context.subscription = ChatService.unreadRef(
          context.ref,
          lastActiveChatTimestamp
        )

        context.subscription.on("value", snap => {
          const newMessages = snap?.val() || {}
          this.checkHostMention(newMessages)
          const unreadMessages = Object.values(newMessages).length
          const pill = unreadMessages > 9 ? `9+` : unreadMessages
          reactiveContext.pill = pill || ""
        })

        context.unsubscribe = () => {
          context.subscription?.off()
          reactiveContext.pill = ""
          console.log("unsubscribed from", reactiveContext.value)
        }
      }
    },
    onWatchAvailableChatOptionsWatchValue: debounce(async function (
      newContexts,
      oldContexts,
      AllContextSubscriptionArray
    ) {
      if (isEqual(newContexts, oldContexts)) {
        return console.log("why re-render?")
      }

      console.log("unsubscribing from all")
      for (let context of AllContextSubscriptionArray) {
        context.unsubscribe && context?.unsubscribe()
      }

      const availableContexts = AllContextSubscriptionArray.filter(
        ctx => this.availableChatOptionsMap[ctx.value]
      )

      for (let context of availableContexts) {
        if (this.isChatVisible && context.value === this.getChatContext) {
          context.unsubscribe && context?.unsubscribe()
        } else {
          await this.subscribe(context)
        }
      }
    },
    500),
    onWatchGetChatContext(newContext, oldContext, AllContextSubscriptionArray) {
      if (oldContext) {
        const oldContextData = AllContextSubscriptionArray.find(
          ctx => ctx.value === oldContext
        )
        if (oldContextData) {
          this.subscribe(oldContextData)
        }
      }
      if (newContext) {
        const newContextdata = AllContextSubscriptionArray.find(
          ctx => ctx.value === newContext
        )
        if (newContextdata) {
          newContextdata.unsubscribe && newContextdata?.unsubscribe()
        }
      }
    },
    checkHostMention(messages) {
      if (!this.isHost) return
      Object.entries(messages).forEach(([id, item]) => {
        if (this.checkedMessages[id]) return

        if ((item as Message).message.includes("@host")) {
          this.$set(this.checkedMessages, id, "@host")
        } else {
          this.$set(this.checkedMessages, id, "@regular")
        }
      })
    }
  }
})
