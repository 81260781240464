var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "chat", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        {
          staticClass: "h-full",
          class: { "no-radius": !_vm.showHeader },
          attrs: { column: "" },
        },
        [
          _vm._t("chat-header", function () {
            return [
              _vm.showHeader
                ? _c(
                    "v-flex",
                    { staticClass: "chat-header", attrs: { shrink: "" } },
                    [
                      [
                        _vm.context === _vm.chatContexts.TEAM
                          ? _c("v-icon", { staticClass: "team-icon" }, [
                              _vm._v(" " + _vm._s(_vm.team.icon) + " "),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "team-name" }, [
                          _vm._v(_vm._s(_vm.titleDisplayName)),
                        ]),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          {
                                            staticClass: "chat-actions",
                                            on: { click: _vm.closeChat },
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("close")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1601002998
                            ),
                          },
                          [_c("span", [_vm._v("Close Chat")])]
                        ),
                      ],
                    ],
                    2
                  )
                : _vm._e(),
            ]
          }),
          _vm.loadingChat
            ? _vm._t("chat-loader", function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center chat-loading" },
                    [_c("rtb-spinner")],
                    1
                  ),
                ]
              })
            : _vm._t(
                "chat-messages",
                function () {
                  return [
                    _c("Messages", {
                      attrs: {
                        messages: _vm.mergedMessages,
                        teamID: _vm.teamID,
                        contextRef: _vm.contextRef,
                        canLazyLoadMessage: _vm.canLazyLoadMessage,
                        context: _vm.context,
                      },
                      on: {
                        LAZYLOAD_MESSAGE: _vm.lazyloadMessage,
                        onSelectedGameIDUpdate: _vm.onSelectedGameIDUpdate,
                      },
                    }),
                  ]
                },
                { data: _vm.chatMessagesSlotData }
              ),
          _vm.canSendMessage
            ? _vm._t(
                "chat-input",
                function () {
                  return [
                    _c(
                      "v-layout",
                      { staticClass: "chat__message-input shrink" },
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              grow: "",
                              "d-flex": "",
                              "align-center": "",
                            },
                          },
                          [
                            _c("v-textarea", {
                              attrs: {
                                "auto-grow": "",
                                rows: "1",
                                rowHeight: _vm.INPUT_HEIGHT,
                                "hide-details": "",
                                placeholder:
                                  _vm.placeholder || "Enter your message...",
                              },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.onEnterMessage.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: _vm.message,
                                callback: function ($$v) {
                                  _vm.message = $$v
                                },
                                expression: "message",
                              },
                            }),
                            _vm.message
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "chat__submit",
                                    attrs: { flat: "", small: "" },
                                    on: { click: _vm.onEnterMessage },
                                  },
                                  [_c("v-icon", [_vm._v("send")])],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.canShowHostCheckbox
                          ? _c(
                              "v-flex",
                              {
                                staticClass:
                                  "chat__message-input__host-checkbox shrink",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "contrast-text-color" },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        dark: "",
                                        dense: "",
                                        label: "Hosts",
                                        "hide-details": "",
                                        reverse: "",
                                        disabled: _vm.loadingChat,
                                      },
                                      model: {
                                        value: _vm.lobbyHostChat,
                                        callback: function ($$v) {
                                          _vm.lobbyHostChat = $$v
                                        },
                                        expression: "lobbyHostChat",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                { data: _vm.chatInputSlotData }
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }