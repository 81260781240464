<template>
  <v-flex d-flex class="chat">
    <v-layout column :class="{ 'no-radius': !showHeader }" class="h-full">
      <slot name="chat-header">
        <v-flex class="chat-header" shrink v-if="showHeader">
          <template>
            <v-icon class="team-icon" v-if="context === chatContexts.TEAM">
              {{ team.icon }}
            </v-icon>
            <div class="team-name">{{ titleDisplayName }}</div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div class="chat-actions" @click="closeChat" v-on="on">
                  <v-icon>close</v-icon>
                </div>
              </template>
              <span>Close Chat</span>
            </v-tooltip>
          </template>
        </v-flex>
      </slot>

      <slot name="chat-loader" v-if="loadingChat">
        <div class="text-center chat-loading">
          <rtb-spinner />
        </div>
      </slot>

      <slot name="chat-messages" :data="chatMessagesSlotData" v-else>
        <Messages
          :messages="mergedMessages"
          :teamID="teamID"
          :contextRef="contextRef"
          :canLazyLoadMessage="canLazyLoadMessage"
          :context="context"
          @LAZYLOAD_MESSAGE="lazyloadMessage"
          @onSelectedGameIDUpdate="onSelectedGameIDUpdate"
        />
      </slot>

      <slot name="chat-input" :data="chatInputSlotData" v-if="canSendMessage">
        <v-layout class="chat__message-input shrink">
          <v-flex grow d-flex align-center>
            <v-textarea
              auto-grow
              rows="1"
              :rowHeight="INPUT_HEIGHT"
              v-model="message"
              hide-details
              :placeholder="placeholder || 'Enter your message...'"
              @keydown.enter="onEnterMessage"
            />
            <v-btn
              class="chat__submit"
              v-if="message"
              flat
              small
              @click="onEnterMessage"
            >
              <v-icon>send</v-icon>
            </v-btn>
          </v-flex>
          <v-flex
            class="chat__message-input__host-checkbox shrink"
            v-if="canShowHostCheckbox"
          >
            <div class="contrast-text-color">
              <v-checkbox
                v-model="lobbyHostChat"
                dark
                dense
                label="Hosts"
                hide-details
                reverse
                :disabled="loadingChat"
              />
            </div>
          </v-flex>
        </v-layout>
      </slot>
    </v-layout>
  </v-flex>
</template>

<script>
import Vue from "vue"
import { debounce } from "lodash"
import { mapGetters, mapActions } from "vuex"
import Messages from "./Messages"
import Chat from "./"

import { CHAT_CONTEXTS, INPUT_HEIGHT } from "./constants"
import { RtbSpinner } from "@/components/ui"
import { db } from "@/firebase"

export default Vue.extend({
  name: "Chat",
  props: {
    messageText: {
      type: String,
      default: undefined
    },
    teamID: {
      required: true
    },
    roomID: {
      required: true
    },
    context: {
      required: true
    },
    showHeader: {
      required: false,
      default: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    canSendMessage: {
      required: false,
      default: true,
      type: Boolean
    },
    isAnonymousChat: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      collapsed: true,
      chatSubscription: null,
      activeChatTimestampSubscription: null,
      messages: [],
      lazyLoadedMessages: [],
      canLazyLoadMessage: true,
      loadingChat: false,
      INPUT_HEIGHT
    }
  },
  components: {
    Messages,
    RtbSpinner
  },
  async beforeDestroy() {
    this.unsubscribeFromChat()
  },
  watch: {
    watchChatContext: {
      handler: debounce(function (newWatchContext, oldWatchContext) {
        if (newWatchContext !== oldWatchContext) {
          this.messages = []
          this.lazyLoadedMessages = []
          this.subscribeToChat(`${this.contextRef}/messages`)
        }
      }, 500),
      immediate: true
    }
  },
  computed: {
    ...mapGetters([
      "getTeamDetail",
      "game",
      "orgID",
      "gameID",
      "isLobbyHostChat"
    ]),
    ...mapGetters("auth", ["isHost", "clientID", "user", "client"]),
    team() {
      return this.getTeamDetail(this.teamID)
    },
    count() {
      return this.getActiveChatTimestamp
    },
    contextRef() {
      return Chat.getRefByContext({
        context: this.context,
        orgID: this.orgID,
        gameID: this.gameID,
        teamID: this.teamID,
        clientID: this.clientID
      })
    },
    watchChatContext() {
      return `${this.roomID}-${this.context}-${this.lobbyHostChat}`
    },
    chatContexts() {
      return CHAT_CONTEXTS
    },
    isLobbyPage() {
      return this.$route.name === "pregame"
    },
    mergedMessages() {
      return [...this.lazyLoadedMessages, ...this.messages]
    },
    lastMessageId() {
      return this.mergedMessages[0]?.id
    },
    lobbyHostChat: {
      get() {
        return this.isLobbyHostChat
      },
      set(value) {
        this.updateIsLobbyHostChat(value)
      }
    },
    titleDisplayName() {
      if (
        [this.chatContexts.LOBBY, this.chatContexts.LOBBY_HOST].includes(
          this.context
        )
      ) {
        return this.client?.name
      } else if (this.chatContexts.GAME === this.context) {
        return this.game?.externalName || "Room Messages"
      } else if (this.chatContexts.TEAM === this.context) {
        return this.team.name
      }
      return ""
    },
    chatMessagesSlotData() {
      return {
        messages: this.mergedMessages,
        teamID: this.teamID,
        contextRef: this.contextRef,
        canLazyLoadMessage: this.canLazyLoadMessage,
        context: this.context,
        LAZYLOAD_MESSAGE: this.lazyloadMessage,
        onSelectedGameIDUpdate: this.onSelectedGameIDUpdate
      }
    },
    chatInputSlotData() {
      return {
        inputHeight: this.INPUT_HEIGHT,
        disableKeyboardShortcut: this.disableKeyboardShortcut,
        enableKeyboardShortcut: this.enableKeyboardShortcut,
        loadingChat: this.loadingChat,
        canShowHostCheckbox: this.canShowHostCheckbox,
        lobbyHostChat: this.lobbyHostChat
      }
    },
    canShowHostCheckbox() {
      return (
        this.isHost &&
        [CHAT_CONTEXTS.LOBBY, CHAT_CONTEXTS.LOBBY_HOST].includes(this.context)
      )
    },
    message: {
      get() {
        return this.messageText
      },
      set(value) {
        this.$emit("update:messageText", value)
      }
    }
  },
  methods: {
    ...mapActions(["updateIsLobbyHostChat"]),
    onEnterMessage(e, message = null) {
      if (!e.shiftKey) {
        e.preventDefault()
        e.target.style.height = `${INPUT_HEIGHT}px`
        const Smessage = message ?? this.message
        if (!Smessage) return

        const payload = {
          contextRef: this.contextRef,
          message: Smessage,
          userID: this.user.id,
          userDetail: {
            userID: this.user.id,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            role: this.user.role
          }
        }

        if (this.isAnonymousChat) {
          payload.anonymous = true
        }

        Chat.addChatMessage(payload)
        this.message = ""
      }
    },
    closeChat() {
      this.$emit("closeChat")
    },
    unsubscribeFromChat() {
      this.chatSubscription && this.chatSubscription.off()
    },
    async subscribeToChat(ref) {
      this.loadingChat = true
      this.unsubscribeFromChat()
      const initialMessages = await Chat.fetchMessages({
        contextRef: this.contextRef,
        userID: this.user.id
      })
      this.messages = initialMessages

      let lastTimeStamp
      try {
        lastTimeStamp = initialMessages[initialMessages - 1]["timestamp"] + 1
      } catch (e) {
        lastTimeStamp = Date.now()
      }

      this.chatSubscription = db
        .auxiliary()
        .ref(ref)
        .orderByChild("timestamp")
        .startAt(lastTimeStamp)

      this.chatSubscription.on("child_added", snapshot => {
        const message = snapshot.val() || {}
        this.messages.push({
          id: snapshot.key,
          messagePayload: message,
          userDetail: {
            firstname: message?.firstname ?? "",
            lastname: message?.lastname ?? ""
          }
        })
      })
      this.loadingChat = false
    },
    async lazyloadMessage({ contextRef, limit }) {
      if (!this.lastMessageId) return
      let lazyLoadedMessages = await Chat.fetchMessages({
        contextRef,
        messageId: this.lastMessageId,
        limit
      })

      this.lazyLoadedMessages = [
        ...lazyLoadedMessages,
        ...this.lazyLoadedMessages
      ]
      // Stop lazy loading messages
      if (lazyLoadedMessages.length < limit) {
        this.canLazyLoadMessage = false
      }
    },
    onSelectedGameIDUpdate(data) {
      this.$emit("onSelectedGameIDUpdate", data)
    }
  }
})
</script>

<style lang="scss">
.chat {
  & > div:not(.no-radius) {
    @extend .rtb-box-shadow;
    border-radius: 7px;
    overflow: hidden;
  }
  .chat-header {
    background: white;
    box-shadow: 0px 0.1px #f36524;
    padding: 5px;
    font-weight: bolder;
    min-height: 50px;
    display: flex;
    z-index: 225;
    .team-icon {
      margin-left: 8px;
      z-index: 33;
    }
    .team-name,
    .game-name {
      flex-grow: 1;
      justify-content: center;
      align-self: center;
      text-align: center;
    }
    .chat-actions {
      align-self: center;
      margin-right: 8px;
      &:hover {
        cursor: pointer;
        filter: brightness(70%);
      }
    }
  }
  &__message-input {
    display: flex;
    width: 100%;
    align-self: center;
    border: 0px;
    background: rgb(252, 251, 251);

    .v-input__slot:after {
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    textarea {
      background: rgb(252, 251, 251);
      line-height: 20px;
      padding: 10px 12px 12px 12px;
      max-width: 100%;
      width: 100%;
    }

    .v-input.v-textarea.v-text-field {
      padding-top: unset;
      margin-top: unset;
    }

    &__host-checkbox {
      display: flex;
      background: currentColor;
      color: var(--primary-accent-color);
      padding: 0px 6px;
      border-bottom-right-radius: 4px;
      .v-label {
        font-size: 13px;
        font-weight: 500;
      }
      .v-input__slot {
        flex-direction: column;
        align-self: center;
      }
      .v-input--selection-controls {
        justify-content: center;
        margin-top: -1px;
        &__input {
          margin-right: 2px;
        }
      }
      .theme--dark.v-icon,
      .accent--text,
      .theme--dark.v-label {
        color: currentColor !important;
      }
    }
  }
  .chat-loading {
    background: #fcfbfb;
    height: 100%;
    display: grid;
    place-items: center;
  }

  &__submit {
    min-width: 0;
    flex: 0 0 auto !important;
    color: var(--primary-accent-color) !important;
  }
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>
