var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-head",
      class:
        ((_obj = {}),
        (_obj["chat-head__" + _vm.position] = _vm.position),
        _obj),
      on: {
        click: function ($event) {
          return _vm.$emit("openChat")
        },
      },
    },
    [
      _vm._t(
        "chat-head-label",
        function () {
          return [
            _c(
              "div",
              { staticClass: "chat-head__button" },
              [
                _c("div", {
                  ref: "handle",
                  staticClass: "chat-head__button-handle",
                  on: {
                    click: function ($event) {
                      return $event.stopImmediatePropagation()
                    },
                  },
                }),
                _vm.shouldDisplayUnreadLabel
                  ? _vm._t("unread", function () {
                      return [
                        _c("div", { staticClass: "chat-head__unread" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("unreadMessageFormat")(
                                  _vm.customUnreadMessage ||
                                    _vm.UNREAD_MESSAGE_COUNT
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    })
                  : _vm._e(),
                _c("span", { staticClass: "chat-head__button-text" }, [
                  _vm._v(" " + _vm._s(_vm.contextLabel) + " "),
                ]),
                _c("SvgIcon", {
                  attrs: { name: "chats-circle", height: "20", width: "20" },
                }),
              ],
              2
            ),
          ]
        },
        {
          labelCount: {
            count: _vm.UNREAD_MESSAGE_COUNT,
            label: _vm.$options.filters.unreadMessageFormat(
              _vm.customUnreadMessage || _vm.UNREAD_MESSAGE_COUNT
            ),
          },
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }