<template>
  <div
    class="chat-head"
    @click="$emit('openChat')"
    :class="{ [`chat-head__${position}`]: position }"
  >
    <slot
      name="chat-head-label"
      :labelCount="{
        count: UNREAD_MESSAGE_COUNT,
        label: $options.filters.unreadMessageFormat(
          customUnreadMessage || UNREAD_MESSAGE_COUNT
        )
      }"
    >
      <div class="chat-head__button">
        <div
          class="chat-head__button-handle"
          ref="handle"
          @click="$event.stopImmediatePropagation()"
        />

        <slot v-if="shouldDisplayUnreadLabel" name="unread">
          <div class="chat-head__unread">
            {{
              (customUnreadMessage || UNREAD_MESSAGE_COUNT)
                | unreadMessageFormat
            }}
          </div>
        </slot>

        <span class="chat-head__button-text">
          {{ contextLabel }}
        </span>

        <SvgIcon name="chats-circle" height="20" width="20" />
      </div>
    </slot>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { debounce } from "lodash"
import Chat from "./"

import { db } from "@/firebase"

export default Vue.extend({
  name: "ChatHead",
  props: {
    teamID: {
      required: true
    },
    context: {
      required: true
    },
    shouldSubscribe: {
      required: false,
      default: true,
      type: Boolean
    },
    customUnreadMessage: {
      required: false,
      default: 0,
      type: Number
    },
    shouldDisplayUnreadLabel: {
      required: false,
      default: true,
      type: Boolean
    },
    position: {
      type: String,
      default: "left",
      validator: str => ["left", "right"].includes(str)
    }
  },
  data() {
    return {
      activeChatTimestampSubscription: null,
      UNREAD_MESSAGE_COUNT: null
    }
  },
  computed: {
    ...mapGetters(["getTeamDetail", "orgID", "gameID"]),
    ...mapGetters("auth", ["clientID", "user"]),
    team() {
      return this.getTeamDetail(this.teamID)
    },
    contextRef() {
      return Chat.getRefByContext({
        context: this.context,
        orgID: this.orgID,
        gameID: this.gameID,
        teamID: this.teamID,
        clientID: this.clientID
      })
    },
    contextLabel() {
      return Chat.getContextLabel(this.context)
    }
  },
  filters: {
    unreadMessageFormat(value) {
      value = parseInt(value) || 0
      return value > 9 ? "9+" : value
    }
  },
  async beforeDestroy() {
    if (this.shouldSubscribe) this.unSubscribeFromUnreadMessage()
  },
  watch: {
    contextRef: {
      handler: debounce(function (newRef, oldRef) {
        if (!this.shouldSubscribe) return
        if (newRef !== oldRef) {
          this.subscribeToUnreadMessage()
        }
      }, 500),
      immediate: true
    }
  },
  methods: {
    async subscribeToUnreadMessage() {
      this.activeChatTimestampSubscription?.off()

      const lastActiveChatTimestamp = await Chat.getLastActiveChatTimestamp({
        contextRef: this.contextRef,
        userID: this.user.id
      })

      this.activeChatTimestampSubscription = db
        .auxiliary()
        .ref(`${this.contextRef}/messages`)
        .orderByChild("timestamp")
        .startAt(lastActiveChatTimestamp)
        .limitToLast(10)

      this.activeChatTimestampSubscription.on("value", snap => {
        this.UNREAD_MESSAGE_COUNT = Object.values(snap?.val() || {}).length
      })
    },
    unSubscribeFromUnreadMessage() {
      try {
        this.activeChatTimestampSubscription.off("value")
        this.activeChatTimestampSubscription = null
      } catch (e) {}
    }
  }
})
</script>

<style lang="scss">
.chat-head {
  $block: &;
  display: flex;
  gap: 10px;
  z-index: 99;
  flex-wrap: nowrap;
  min-height: 0;
  min-width: 0;

  &__left {
    flex-direction: row;
  }

  &__right {
    flex-direction: row-reverse;
  }

  &__unread {
    z-index: 9;
    position: absolute;
    display: flex;
    height: 27px;
    width: 27px;
    background: rgb(197, 9, 9);
    color: white;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    bottom: calc(100% - 13px);

    #{$block}__left & {
      left: -13px;
    }

    #{$block}__right & {
      right: -13px;
    }
  }

  &__button {
    cursor: pointer;
    position: relative;
    color: #e8e9eb;
    background: #292932;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 8px 0 rgba(59, 64, 76, 0.8);
    padding: 6px 12px;
    text-decoration-color: rgb(0, 0, 0);
    text-decoration-line: none;
    text-decoration-style: solid;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen-Sans,
      Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-stretch: 100%;
    font-style: normal;
    font-variant-caps: normal;
    font-weight: 410;
    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 6px;
      border-top: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      cursor: move;
    }
  }

  &__button-handle {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 38px;
    cursor: move;
  }

  &__button-text {
    margin-left: 12px;
    margin-right: 8px;
  }
}
</style>
